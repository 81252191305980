<template>
  <div>
    <h3 v-if="company">
      {{ company.name }}
    </h3>
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'name'">
          <b-avatar variant="info">
            <feather-icon icon="UserIcon" />
          </b-avatar>
          {{ props.row.name }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>
<script>
import {
  BAvatar, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import useApollo from '@/plugins/graphql/useApollo'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      pageLength: 3,
      dir: false,
      searchTerm: '',
      columns: [{ label: 'ID', field: 'id' }, { label: 'Name', field: 'name' },
      ],
      company: null,
      rows: [],
    }
  },
  created() {
    const auth = getUserData()
    useApollo.company.getMyusers({ userId: auth.id }).then(response => {
      const { data } = response
      const { companyOwner } = data
      const { company } = companyOwner
      this.rows = company.users.data
      this.company = company
    })
  },
}
</script>
